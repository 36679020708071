import { forwardRef, useState } from "react"
import Icon from "lib/components/Icons"
import Text from "lib/components/Text"

export interface InputPasswordProps {
  inline?: boolean;
  label?: string;
  placeholder: string;
  onChange(object): void;
  value?: string | number;
  name: string;
  disabled?: boolean;
  maxLength?: number;
  success?: boolean;
  error?: boolean;
  message?: string;
  labelColor?: "gray" | "dark"
}

const InputPassword = forwardRef<HTMLInputElement, InputPasswordProps>(({
  inline, label, placeholder, onChange, value, name, disabled, maxLength, success, error, message, labelColor = "gray",
}: InputPasswordProps, inputPasswordRef) => {
  InputPassword.displayName = "InputPassword"
  const [showPassword, setShowPassword] = useState(false)

  let borderColor = "border-[#D1D5DB]"
  let borderFocus = "border-gray-400"
  let iconType = ""
  let iconColor = "gray"
  let inlineStyle = "flex-col"
  let labelStyle = "mb-2"
  let labelValue = <div />

  if (inline) {
    inlineStyle = "flex-row items-center"
    labelStyle = "mr-2"
  }

  if (success) {
    iconType = "check"
    iconColor = "green"
  }

  if (error) {
    borderColor = "border-red-500"
    borderFocus = "border-red-500"
    iconType = "error"
    iconColor = "red"
  }

  if (label) {
    if (label.includes("*")) {
      labelValue = (
        <div className="flex gap-x-1">
          <Text type="small-body" color={labelColor}>
            {label.replace(" *", "")}
          </Text>
          <Text weight="bold" type="small-body" color="danger">
            *
          </Text>
        </div>
      )
    } else {
      labelValue = (
        <Text type="small-body" color={labelColor}>
          {label}
        </Text>
      )
    }
  }

  let iconStyle = "right-2 top-2"
  let paddingRight = "pr-4"

  if (iconType) {
    iconStyle = "right-4 top-2"
    paddingRight = "pr-12"
  }

  return (
    <div className={`flex ${inlineStyle}`}>
      {label
        && (
          <div className={labelStyle}>
            {labelValue}
          </div>
        )}
      <div className="relative flex-1">
        <input
          className={`placeholder-muted w-full rounded-md text-sm border ${borderColor} p-2 px-4 ${paddingRight} focus:outline-none focus:${borderFocus}`}
          placeholder={placeholder}
          onChange={(event) => onChange(event)}
          value={value}
          name={name}
          disabled={disabled}
          type={!showPassword ? "password" : "text"}
          maxLength={maxLength}
          ref={inputPasswordRef}
          autoComplete="new-password"
        />

        {iconType
          && (
            <div className="absolute right-2 top-2 cursor-pointer">
              <Icon iconName={iconType} width={24} height={24} color={iconColor} />
            </div>
          )}

        <div className={`absolute ${iconStyle} cursor-pointer`}>
          <Icon onClick={() => setShowPassword(!showPassword)} iconName={showPassword ? "eye-slash" : "eye"} width={24} height={24} />
        </div>
      </div>
      {error
        && (
          <div className="mt-1">
            <Text type="small-body" color="danger">{message}</Text>
          </div>
        )}
    </div>
  )
})

export default InputPassword
